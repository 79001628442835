import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import cx from 'classnames';

import Icon from '@dx-ui/osc-icon';

import type { GetHotelHomePageQuery } from '../../generated/types';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { getDistance } from '../../helpers/property-utils';

const AirportShuttleInfo = ({
  transportations,
}: {
  transportations: NonNullable<
    GetHotelHomePageQuery['hotel']
  >['airports'][number]['transportations'];
}) => {
  const { t } = useTranslation('hotel-location');

  if (transportations.length > 0) {
    return transportations.map((transportation) => (
      <div className="px-4 pb-2" key={transportation?._id}>
        <div className="flex gap-x-1 py-2 text-sm font-semibold">
          <Icon name="check" size="sm" /> <span>{t('available')}</span>
          <Icon name="check" size="sm" />
          <span>
            {transportation?.charge && transportation.charge > 0 ? t('fee') : t('complimentary')}
          </span>
        </div>
        <p className="text-base">{transportation?.desc}</p>
      </div>
    ));
  }
  return (
    <div className="flex gap-1 px-4 py-2">
      <Icon name="close" size="sm" />
      <p className="text-sm font-semibold">{t('notAvailable')}</p>
    </div>
  );
};

export const AirportInfo = ({
  airports,
}: {
  airports?: NonNullable<GetHotelHomePageQuery['hotel']>['airports'];
}) => {
  const { isTailored } = useGlobalConfig();
  const { t } = useTranslation('hotel-location');
  const language = useRouter().locale || 'en';

  return (
    <ul>
      {airports?.map(
        ({ name, code, distance, defaultDistanceUnit: distanceUnit, transportations }) => (
          <li className="flex flex-col" key={code} data-testid="airportInfoElement">
            <div
              className={cx('mb-2 flex justify-between border-b p-2', {
                'border-bg-alt': !isTailored,
                'border-primary': isTailored,
              })}
            >
              <div className="flex gap-x-1 pr-5 text-base font-bold">
                <Icon name="airport" />
                <span>{name}</span>
              </div>
              {distance !== null && distanceUnit ? (
                <div className="whitespace-nowrap text-left">
                  {getDistance({ t, distance, distanceUnit, language })}
                </div>
              ) : null}
            </div>
            <p className="px-4 text-base">{t('airportShuttle')}</p>
            <AirportShuttleInfo transportations={transportations} />
          </li>
        )
      )}
    </ul>
  );
};
